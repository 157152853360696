@keyframes spinner-4t3wzl {
  0% {
     transform: rotate(0deg) translateY(-200%);
  }

  60%, 100% {
     transform: rotate(360deg) translateY(-200%);
  }
}

.back {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner {
    position: relative;
    width: 8px;
    height: 8px;
  }

  .spinner div {
      animation: spinner-4t3wzl 2.25s infinite backwards;
      background-color: #f8f8f8;
      border-radius: 50%;
      height: 100%;
      position: absolute;
      width: 100%;
  }

  .spinner div:nth-child(1) {
      animation-delay: 0.18s;
      background-color: rgba(248,248,248,0.9);
  }

  .spinner div:nth-child(2) {
      animation-delay: 0.36s;
      background-color: rgba(248,248,248,0.8);
  }

  .spinner div:nth-child(3) {
      animation-delay: 0.54s;
      background-color: rgba(248,248,248,0.7);
  }

  .spinner div:nth-child(4) {
      animation-delay: 0.72s;
      background-color: rgba(248,248,248,0.6);
  }

  .spinner div:nth-child(5) {
      animation-delay: 0.8999999999999999s;
      background-color: rgba(248,248,248,0.5);
  }

}
