@import '../../../App.scss';

.logo {
  font-size: 48px;
  font-family: 'Changa One', cursive;
  font-weight: 400;
  background: -webkit-linear-gradient(45deg, $yellow, $pink);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
}

.close {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 1.2rem;
  color: $purple;
  cursor: pointer;
}

.p {
  width: 80%;
  font-size: 1.2rem;
  margin: 40% auto;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  & span {
    display: inline-block;
    margin-bottom: 16px;

    &::before {
      content: '';
      position: absolute;
      width: 15px;
      background-color: $pink;
      transform: rotate(40deg);
      height: 15px;
      border-radius: 15px;
      right: -16px;
      bottom: 84px;
      z-index: -1;
    }// &::before
  
    &::after {
      content: '';
      position: absolute;
      width: 15px;
      background-color: $purple;
      transform: rotate(40deg);
      height: 15px;
      border-radius: 15px;
      right: 40px;
      top: -44px;
      z-index: -1;
    }// &::after
  }

  &::before {
    content: '';
    position: absolute;
    width: 100px;
    background-color: $pink;
    transform: rotate(40deg);
    height: 15px;
    border-radius: 15px;
    right: -54px;
    z-index: -1;
  }// &::before

  &::after {
    content: '';
    position: absolute;
    width: 80px;
    background-color: $purple;
    transform: rotate(40deg);
    height: 15px;
    border-radius: 15px;
    right: -8px;
    z-index: -1;
  }// &::after
}

.banner {
  background-image: url(../../../images/Imagem-Background.png);
  background-size: cover;
  background-position-x: 700px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 471px;
  padding: 48px 35px 80px;

  & h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 55px;
  }

  & p {
    font-size: 24px;
  }
}

.sect2 {
  height: 370px;
  padding: 48px 42px;
  background-color: $yellow;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  & h3 {
    font-size: 24px;
    font-weight: 700;
  }

  & .link {
    text-decoration: none;
    font-size: 24px;
    font-weight: 700;
  }
}

.card_sect {
  display: flex;
  flex-direction: column;
  padding: 48px 35px;
  background-color: $purple;
  color: white;

  & h2 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 40px;
  }
}

.cards {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card {
  background-color: white;
  color: black;
  margin: 40px 0;
  border-radius: 10px;
  padding: 40px 24px;
  width: 320px;

  & h3 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 32px;
  }

  & p {
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
  }
}

.footer {
  background-color: $yellow;
  height: 360px;
  padding: 48px 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & h2 {
    font-size: 32px;
  }
}
