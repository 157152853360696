@import '../../../App.scss';

.form {
  margin: 80px auto;
  width: 50%;
  padding: 40px 35px;
  background-color: $yellow;

  & .gender {
    display: flex;

    & label {
      font-size: 18px;
      width: 32%;
      display: flex;
      align-items: center;
      margin: 2px 4px;
    }
  }

  & .activities {
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0;

    & label {
      font-size: 18px;
      width: 32%;
      display: flex;
      align-items: center;
      margin: 4px 4px;
    }
  }

  & .practice {
    display: flex;

    & section {
      width: 50%;

      & label {
        font-size: 18px;
        width: 100%;
        display: flex;
        align-items: center;
        margin: 4px 4px;

        & input {
          margin: 0 8px;
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  & p, & legend {
    font-size: 20px;
    text-align: center;
    margin: 8px 0;
  }

  & input, & select {
    width: 80%;
    display: block;
    margin: 12px auto;
    padding: 12px;
    font-size: 24px;
    border: none;
    border-radius: 10px;
    border-bottom: 1px solid $pink;
    background-color: white;
  }

  .birth {
    text-align: center;
    border: 1px solid red;
  }

  & label {
    font-size: 18px;
    margin: 2px 4px;

    & input[type=radio], & input[type=checkbox] {
      margin: 0 12px;
      width: 16px;
      height: 16px;
    }
  }

  & .goals, & .personalities {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    & label {
      width: 45%;
      display: flex;
      margin: 8px 0;
    }
  }

  & .indications {
    display: flex;
    flex-direction: column;
    text-align: center;

    & textarea {
      width: 80%;
      margin: 12px auto;
      height: 80px;
      resize: none;
      border-radius: 10px;
      border: none;
      border-bottom: 1px solid $pink;
      padding: 8px;
      font-size: 24px;
    }
  }

  & button {
    font-size: 24px;
    font-weight: 700;
    margin: 12px 50% 0;
    transform: translateX(-50%);
    min-height: 50px;
  }
}
