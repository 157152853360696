@import url('https://fonts.googleapis.com/css2?family=Changa+One&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

$yellow: #ffcb09;
$pink: #d90c8d;
$purple: #5c2e91;

:root {
  --yellow: #ffcb09;
  --purple: #5c2e91;
  --pink: #d90c8d;
  --light-purple: #bc91ee;
  --light-pink: #d260a8;
}

p {
  font-family: 'Montserrat';
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::selection {
  color: var(--light-purple);
  background-color: $purple;
}

body {
  font-family: 'Open Sans', sans-serif;
}

button, .button {
  padding: 16px 45px;
  border: none;
  margin: 0;
  border-radius: 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  background-color: $pink;
  color: white;
}

button:hover {
  opacity: 0.8;
}

button:active {
  box-shadow: 2px 2px 5px $purple;
}
