@import '../../App.scss';

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  & input {
    width: 80%;
    margin: auto;
    padding: 16px;
    font-size: 24px;
    border: none;
    border-radius: 10px;
    border-bottom: 1px solid $pink;
    background-color: white;
  }

  & button {
    width: 80%;
    margin: auto;
    font-size: 24px;
    font-weight: 700;
    cursor: pointer;
  }
}
